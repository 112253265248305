<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-24 17:14:21
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-28 14:51:17
-->
<template>
  <div class="quarterly_rebate">
    <a-table :data-source="tableData" bordered rowKey="id" :pagination="page" @change="changeTable" :columns="columns">
      <span slot="index" slot-scope="text, row, index">{{index + 1}}</span>
    </a-table>
  </div>
</template>

<script>
  import { QuarterlyColumns } from './components/colums.js'
  import { QuarterlyData } from './components/TableData.js'
  export default {
    data() {
      return {
        columns: QuarterlyColumns,
        tableData: [],
        page: {
          pageNumber: 1,
          pageSize: 10,
          total: 0
        }
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.axios.get('/api/customer/customer/customerInfo/listCustomerQuarterTurnover', {params: this.page}).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        })
      },
      /**
       * 更改分页
       */
      changeTable(pagination) {
        this.page = pagination
        this.page.pageNumber = pagination.current
        this.getList()
      },
    }
  }
</script>

<style lang="scss">
  
</style>
